import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppStore, UserStoreSelectors } from '@hosty-app/app-store';
import { Profile } from '@hosty-app/types';

import { MyCustomModalService } from '@hosty-web/services';

@Component({
  selector: 'hosty-app-account-blocked',
  templateUrl: './account-blocked.component.html',
  styleUrls: ['./account-blocked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterLink, AsyncPipe],
})
export class AccountBlockedComponent {
  public profile$: Observable<Profile>;

  cardIssue = false;
  subscriptionIssue = false;

  constructor(
    private readonly modalService: MyCustomModalService,
    private readonly store$: Store<IAppStore>,
  ) {
    this.profile$ = this.store$.select(UserStoreSelectors.selectUserProfile);
    this.cardIssue = !!this.modalService.data.cardIssue;
    this.subscriptionIssue = !!this.modalService.data.subscriptionIssue;
  }

  public close(decision: boolean): void {
    this.modalService.close(decision);
  }
}
