<div *ngIf="profile$ | async as profile" class="account-block">
  <div class="blocked-img">
    <img src="assets/images/bill-trial.svg" />
  </div>
  <div class="blocked-context">
    <div class="blocked-title">Account is locked</div>
    <ng-container>
      <div *ngIf="profile.role === 'ROLE_SITE_SUPER_ADMIN'; else user">
        <div class="blocked-text">
          {{
            subscriptionIssue
              ? 'Your trial period has ended and you need to select a billing plan to continue using Hosty.'
              : 'Please add active payment card to continue using Hosty.'
          }}
        </div>
        <div class="d-flex">
          <button
            *ngIf="subscriptionIssue"
            class="btn btn-primary"
            routerLink="/profile/subscriptions"
            (click)="close(true)"
          >
            Select a Billing Plan
          </button>
          <button
            *ngIf="cardIssue"
            class="btn btn-primary"
            routerLink="/profile/billing"
            (click)="close(true)"
          >
            Update billing
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #user>
      <div>
        <div class="blocked-text--user">
          Account Locked due to payment issue. Reach out to your account admin to resolve.
        </div>
      </div>
    </ng-template>
  </div>
</div>
