import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

import { MyCustomModalService } from '@hosty-web/services';

import { AccountBlockedComponent } from '../../modules/accounts/components/account-blocked/account-blocked.component';

@Injectable({
  providedIn: 'root',
})
export class BlockUserInterceptor implements HttpInterceptor {
  constructor(
    private readonly modalService: MyCustomModalService,
    private readonly route: Router,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          (error.error.error === 'access_denied_user_without_subscription_active' ||
            error.error.error === 'access_denied_user_without_active_card') &&
          this.route.url !== '/profile/subscriptions'
        ) {
          if (!this.modalService.currentModal) {
            this.modalService.open(AccountBlockedComponent, {
              disableClose: true,
              hasBackdrop: true,
              data: {
                cardIssue: error.error.error.includes('card'),
                subscriptionIssue: error.error.error.includes('subscription'),
              },
            });
          }
        }
        return throwError(error);
      }),
    );
  }
}
